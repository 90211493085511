import React, {useEffect, useRef} from 'react'
import styled from 'styled-components';
import gsap from 'gsap'

import Detective from '../images/Detective.png'

import RowWrapper from '../utils/rowWrapper';

import Layout from '../components/layout';

 
const Container = styled.div`
 padding-top: 8rem;

 @media ${props => props.theme.breaks.down('md')} {
  padding-top: 3rem;
 }
`

const Title = styled.div`
  margin: 2rem auto;
   h1 {
     text-align: center;
     font-size: 12rem;
     color: #2499a5;
     /* font-weight: normal; */
   }
`

const Subtext = styled.p`
  margin: 0 auto;
  line-height: 2.6rem;
  font-size: 1.8rem;
  text-align: center;
  color: #333;
  max-width: 50rem;
`

const Main = styled.section`
  /* min-height: 30rem; */
  margin: 0 auto;
  /* margin-top: 5rem; */

  @media ${props => props.theme.breaks.down('md')} {
    
  }
`

const Image = styled.div`
  width: 30rem;
  height: 20rem;
  margin: 0 auto;

 img {
   width: 100%;
   object-fit: cover;
   object-position: center bottom;
 }

 @media ${props => props.theme.breaks.down('md')} {
  width: 70%;
  padding: 2rem 0;
 }
`

const FourOFour = ({location}) => {

 return (
  <Layout location={location.pathname} pageTitle="Novosoft - 404">
  <Container>
    <RowWrapper>
      <Main>
        <Title>
          <h1>404</h1>
        </Title>

        <Image>
          <img src={Detective} />
        </Image> 

        <Subtext>
          Uh Oh! You're seeing things you should'nt be seeing.. Quickly navigate back to the home page
        </Subtext>
      </Main>
    
    </RowWrapper>
  </Container>
 </Layout>
 )
}

export default FourOFour;